import React from 'react'
import axios from 'axios'

import Team from '../components/teamList'

export default class ServicesPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        try {
            const WOW = require('wowjs')
            const wow = new WOW.WOW()
            wow.init()
        } catch (e) {
            console.error(e)
        }

        axios
            .get(`${process.env.API_URL}/umbraco/api/nis/get`)
            .then(result => {
                this.setState({
                    data: result.data,
                })
            })
            .catch(error => { })
    }

    render() {
        const { data } = this.state
        let description = ''
        let objectives = ''
        let photoUrl = ''
        let services = [];
        let team = [];
        let contact = ''

        if (data) {
            description = data.Description
            objectives = data.Objectives
            photoUrl = `${process.env.API_URL}${data.Photo}`
            services = data.Services || []
            team = data.TeamMembers || []
            contact = data.Contact
        }

        return (
            <div>
                <section className="section-top">
                    <div className="container">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="section-top-title">
                                <h2>Núcleo de Intervenção Sistémica</h2>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-xs-12" />
                    </div>
                </section>
                <section id="about" className="about_us section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 col-sm-12 col-xs-12">
                                <div className="about_content">
                                    <span dangerouslySetInnerHTML={{ __html: description }} />
                                </div>
                                <div className="spacer-sm">
                                    <h2>Objetivos</h2>
                                </div>
                                <div className="about_content">
                                    <span dangerouslySetInnerHTML={{ __html: objectives }} />
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-12 col-xs-12">
                                <div className="about-slide">
                                    <img src={photoUrl} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="our_activites">
                    <div className="container">
                        <div className="row text-center">
                            <div className="section-title wow zoomIn">
                                <h2>Serviços</h2>
                                <span />
                            </div>
                            {services.map((service, idx) => {
                                return (
                                    <div key={idx} className="col-sm-12">
                                        <div className="single_activites card xl">
                                            <div className="grid-container-2x-lg">
                                                <div className="grid-item">
                                                    <img alt="Logo" src={`${process.env.API_URL}${service.Photo}`} />
                                                </div>
                                                <div className="grid-item training-card-text">
                                                    <h4 className="text inline">{service.Name.replace(/&nbsp;/g, '')}</h4>
                                                    <h4 className="text"></h4>
                                                    <div className="training-list" dangerouslySetInnerHTML={{ __html: service.Description }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
                <Team dataFromParam data={team} showHeader />
                <section id="about-outro" className="about_us section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="about_content">
                                    <span dangerouslySetInnerHTML={{ __html: contact }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}
